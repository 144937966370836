import { transactionFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
    filter: {
      startKey: 'startedAt',
      endKey: 'endedAt',
      type: 'datetime',
    },
  },
  {
    key: 'orderNumber',
    label: 'Order Number',
    visibility: ['view'],
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    map: {
      pending: 'warning',
      rejected: 'danger',
      failed: 'danger',
      succeeded: 'success',
      refunded: 'info',
    },
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.user ? `/user/${data.user.id}` : null
      },
    },
  },
  {
    key: 'creator.email',
    type: 'email',
    label: 'Creator email',
    visibility: ['view'],
  },
  {
    key: 'creator.profile.countryCode',
    type: 'country',
    label: 'Creator country',
    visibility: ['view'],
    filter: {
      key: 'creatorCountries',
      type: 'select',
      options: [
        { value: 'PH', text: 'Philippines' },
        { value: 'TH', text: 'Thailand' },
        { value: 'MY', text: 'Malaysia' },
        { value: 'SG', text: 'Singapore' },
        { value: 'ID', text: 'Indonesia' },
        { value: 'TW', text: 'Taiwan' },
      ],
    },
  },
  {
    key: 'creator.tier',
    label: 'Tier',
    type: 'badge',
    map: {
      BOOST_PLUS: 'primary',
      BOOST: 'warning',
      FREE: 'success',
    },
    filter: {
      key: 'tiers',
      type: 'multiple',
      options: [
        { value: 'FREE', text: 'Free' },
        { value: 'BOOST', text: 'Boost' },
        { value: 'BOOST_PLUS', text: 'Boost Plus' },
      ],
    },
  },
  // all value is in usd or gold
  {
    key: 'token',
    label: 'Amount',
    type: {
      callback(data) {
        return data.currency === 'gold' || data.usecase === 'TOPUP' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency !== 'gold' && data.usecase !== 'TOPUP' ? '$' : undefined
      },
    },
  },
  {
    key: 'price',
    label: 'Currency',
    prefix: {
      callback(data) {
        return data.currency ? `${data.currency.toUpperCase()} ` : null
      },
    },
  },
  transactionFilterPayment,
  {
    key: 'updatedAt',
    label: 'Updated at',
    type: 'datetime',
  },
]
