import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableSort: get('referralTableSort'),
    tableFilter: get('referralTableFilter', []),
    detailData: get('referralDetailData'),
    tablePagination: get('referralTablePagination'),
    tableKeyword: get('referralTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      referralFinished_status: item.referral_finished ? 'successful' : 'pending',
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_DETAIL_DATA(state, val) {
      set('referralDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('referralTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('referralTableFilter', val)
      state.tableFilter = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('referralTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('referralTableKeyword', val)
      state.tableKeyword = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/referrals', { params }) // TODO: data: null
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/users/referral/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/users/referral/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/users/referral', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
