import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/voucher-usage'

export default {
  namespaced: true,
  state: {
    tableFields: get('voucherUsageTableFields', fields),
    tableData: [],
    tableSort: get('voucherUsageTableSort'),
    tableFilter: get('voucherUsageTableFilter', []),
    detailData: get('voucherUsageDetailData'),
    tablePagination: get('voucherUsageTablePagination'),
    tableKeyword: get('voucherUsageTableKeyword'),
    loadingTable: false,
    loadingSubmit: false,
    loadingDetail: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('voucherUsageTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('voucherUsageTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('voucherUsageTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('voucherUsageTableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('voucherUsageTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('voucherUsageTableFilter', val)
      state.tableFilter = val
    },
    SET_DETAIL_DATA(state, val) {
      set('voucherUsageDetailData', val)
      state.detailData = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
  },
  actions: {
    async getTableData({ commit, getters }, idVoucher) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      await this._vm.$http.get('/v1/bo/users/vouchers', {
        params: {
          ...params,
          voucherGeneratorID: idVoucher,
        },
      })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/settings/voucherUsage-generators/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
  },
}
