export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'featuredImage',
    label: 'Featured Image',
    type: 'image',
  },
  // TODO: Supporter Email
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'slug',
    label: 'Slug',
  },
  {
    key: 'createdAt',
    label: 'Created At',
    type: 'datetime',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
    type: 'datetime',
  },
]
