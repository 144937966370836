export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'gameID',
    label: 'Game',
  },
  {
    key: 'gamerID',
    label: 'Gamer',
  },
  {
    key: 'sort',
    label: 'Sort',
  },
  {
    key: 'createdAt',
    label: 'Date',
    type: 'datetime',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
    type: 'datetime',
  },
]
