import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/pay-per-view'

export default {
  namespaced: true,
  state: {
    tableFields: get('payPerViewTableFields', fields),
    tableData: [],
    tableSort: get('payPerViewTableSort'),
    tableFilter: get('payPerViewTableFilter', []),
    detailData: get('payPerViewDetailData'),
    tablePagination: get('payPerViewTablePagination'),
    tableKeyword: get('payPerViewTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => {
      let supporter = null
      let creator = null

      supporter = {
        id: item.buyer_user_id || item.usecase_data?.data?.subscriber_user_id,
        avatar: item.buyer_user_avatar_url,
        nickname: item.buyer_user_nickname || item.usecase_data?.data?.subscriber_user_nickname,
        email: item.buyer_user_email || item.usecase_data?.data?.subscriber_user_email,
        countryCode: item.buyer_user_profile.countryCode,
      }

      creator = {
        id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
        avatar: item.seller_user_avatar_url,
        nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        email: item.seller_user_email || item.usecase_data?.data?.creator_user_email,
        countryCode: item.seller_user_profile.countryCode,
      }

      return ({
        ...item,
        id: item.human_id,
        supporter,
        creator,
      })
    }),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      per_page: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('payPerViewTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('payPerViewTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('payPerViewTable', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('payPerViewTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('payPerViewTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('payPerViewDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('payPerViewTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('payPerViewTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    async getTableData({
      state, commit, getters, rootState,
    }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v2/payment/admin/transactions?usecases=PAY_PER_VIEW', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', {
            currentPage: state.tablePagination?.currentPage ?? 1,
            totalPage: data.total / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
            perPage: state.tablePagination?.per_page ?? rootState.appConfig.settings.tablePerPage,
            totalData: data.total,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
  },
}
