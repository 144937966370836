export default {
  namespaced: true,
  state: {
    tableData: [],
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/standard-bad-word-list', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data.words || [])
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/bo/users/standard-bad-word-list', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, text) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete('/v1/bo/users/standard-bad-word-list', { data: { word: text } })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
