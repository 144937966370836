import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import findIndex from 'lodash/findIndex'
import intersectionBy from 'lodash/intersectionBy'
import differenceWith from 'lodash/differenceWith'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
import isMatch from 'lodash/isMatch'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'
import truncate from 'lodash/truncate'
import cloneDeep from 'lodash/cloneDeep'

const plugin = {
  install() {
    Vue.prototype._ = {
      isEqual,
      some,
      findIndex,
      intersectionBy,
      differenceWith,
      find,
      filter,
      isEmpty,
      set,
      get,
      isMatch,
      merge,
      sortBy,
      truncate,
      cloneDeep,
    }
  },
}

Vue.use(plugin)
