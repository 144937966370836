export default {
  namespaced: true,
  state: {
    data: {},
    loadingDetail: false,
  },
  mutations: {
    RESET_DATA(state) {
      state.data = {}
    },
    SET_DATA(state, val) {
      state.data = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
  },
  actions: {
    getData({ commit }) {
      commit('RESET_DATA')
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get('/v2/payment/payment-info?usecases=PROCESSING_FEES_LIST')
        .then(({ data }) => {
          commit('SET_DATA', data?.fees_list)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
  },
}
