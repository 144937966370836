import { transactionFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'userID',
    label: 'User',
    visibility: false,
    filter: {
      key: 'userID',
      title: 'name',
      type: 'profile',
      stateOptions: 'transaction.userData',
      actionSearch: 'transaction/getUserData',
      actionDetail: 'user/getUserData',
    },
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
    filter: {
      startKey: 'startedAt',
      endKey: 'endedAt',
      type: 'datetime',
    },
  },
  {
    key: 'orderNumber',
    label: 'Order Number',
    visibility: ['view'],
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      pending: 'warning',
      rejected: 'danger',
      failed: 'danger',
      succeeded: 'success',
      refunded: 'info',
    },
  },
  {
    key: 'supporter.nickname',
    label: 'Supporter',
    type: 'profile',
    sortable: false,
    image: 'supporter.avatar',
    url: {
      callback(data) {
        return data.supporter && data.supporter.id ? `/user/${data.supporter.id}` : null
      },
    },
  },
  {
    key: 'supporter.email',
    type: 'email',
    label: 'Supporter email',
    visibility: ['view'],
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator && data.creator.id ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'creator.email',
    type: 'email',
    label: 'Creator email',
    visibility: ['view'],
  },
  {
    key: 'creator.profile.countryCode',
    type: 'country',
    label: 'Creator country',
    visibility: ['view'],
  },
  {
    key: 'usecase_text',
    label: 'Usecase',
    type: 'dot',
    map: {},
    sortable: false,
    filter: {
      key: 'title',
      type: 'select',
      options: [
        { value: 'Withdrawal', text: 'Withdraw' },
        { value: 'Donation', text: 'Donation' },
        { value: 'Topup', text: 'Topup' },
        { value: 'Paid Membership', text: 'Paid Membership' },
        { value: 'Paid Upgrade Plan', text: 'Paid Upgrade Plan' },
        { value: 'Redeem Voucher', text: 'Redeem Voucher' },
      ],
    },
  },
  {
    key: 'title',
    label: 'Title',
    sortable: false,
    visibility: ['view'],
  },
  {
    key: 'description',
    label: 'Description',
    visibility: ['view'],
  },
  // all value is in usd or gold
  {
    key: 'token',
    label: 'Amount',
    type: {
      callback(data) {
        return data.currency === 'gold' || data.usecase === 'TOPUP' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency !== 'gold' && data.usecase !== 'TOPUP' ? '$' : undefined
      },
    },
  },
  {
    key: 'price',
    label: 'Currency',
    prefix: {
      callback(data) {
        return data.currency ? `${data.currency.toUpperCase()} ` : null
      },
    },
  },
  transactionFilterPayment,
  {
    key: 'type',
    label: 'Type',
    type: 'badge',
    visibility: ['view'],
    map: {
      deposit: 'success',
      credit: 'warning',
    },
  },
  {
    key: 'payment.transactionID',
    label: 'Reference ID',
    visibility: ['view'],
  },
  {
    key: 'updatedAt',
    label: 'Paid at',
    type: 'datetime',
    visibility: ['view'],
  },
]
