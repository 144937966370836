export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Recorded At',
    type: 'datetime',
  },
  {
    key: 'objectName',
    label: 'Object',
    filter: {
      key: 'objectName',
      type: 'select',
      options: [
        { value: 'User', text: 'User' },
        { value: 'Wallet', text: 'Wallet' },
      ],
    },
  },
  {
    key: 'objectID',
    type: 'id',
    label: 'Object ID',
    url: {
      callback(data) {
        if (data.objectName === 'Wallet') {
          return `/wallet/${data.objectID}`
        }

        if (data.objectName === 'User') {
          return `/user/${data.objectID}`
        }

        return `/user/${data.objectID}`
      },
    },
  },
  {
    key: 'event',
    type: 'dot',
    label: 'Event',
    map: {
      Created: 'info',
      Updated: 'warning',
      Deleted: 'danger',
    },
  },
  {
    key: 'description',
    label: 'Description',
    visibility: ['view'],
  },
  {
    key: 'user.nickname',
    label: 'Caused By',
    type: 'profile',
    image: 'user.avatar',
    url: {
      callback(data) {
        return data.user ? `/user/${data.user.id}` : null
      },
    },
  },
  {
    key: 'ipAddress',
    label: 'IP Address',
    visibility: ['view'],
  },
  {
    key: 'meta',
    label: 'Changes',
    type: 'meta',
    visibility: ['view'],
  },
]
