import { subscriptionFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
    // filter: {
    //   startKey: 'startedAt',
    //   endKey: 'endedAt',
    //   type: 'datetime',
    // },
  },
  {
    key: 'code',
    label: 'Order Number',
    visibility: ['view'],
  },
  {
    key: 'subscriber.nickname',
    label: 'Subscriber',
    type: 'profile',
    image: 'subscriber.avatar',
    url: {
      callback(data) {
        return data.subscriber ? `/user/${data.subscriber.id}` : null
      },
    },
  },
  {
    key: 'subscriber.profile.countryCode',
    label: 'Subscriber country',
    type: 'country',
    visibility: ['view'],
  },
  {
    key: 'subscriber.email',
    label: 'Subscriber email',
    type: 'email',
    visibility: ['view'],
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'creator.profile.countryCode',
    label: 'Creator country',
    type: 'country',
    visibility: ['view'],
  },
  {
    key: 'creator.email',
    label: 'Creator email',
    type: 'email',
    visibility: ['view'],
  },
  subscriptionFilterPayment,
  {
    key: 'type',
    label: 'Type',
    type: 'dot',
    map: {
      'one-time': 'success',
      'auto renewal': 'warning',
    },
    // filter: {
    //   key: 'title',
    //   type: 'select',
    //   options: [
    //     { value: 'creation', text: 'Creation' },
    //     { value: 'renewal', text: 'Renewal' },
    //   ],
    // },
  },
  {
    key: 'active_status',
    label: 'Status',
    type: 'badge',
    map: {
      active: 'success',
      inactive: 'danger',
    },
    filter: {
      key: 'isActive',
      type: 'select',
      options: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' },
      ],
    },
  },
  {
    key: 'amount',
    label: 'Amount',
    prefix: '$',
    suffix: ' USD',
    visibility: ['view'],
  },
  {
    key: 'subscriptionStart',
    label: 'Started at',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'subscriptionEnd',
    label: 'Ended at',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'payment_status',
    label: 'Payment status',
    type: 'dot',
    map: {
      completed: 'success',
    },
    visibility: ['view'],
  },
  {
    key: 'cancelled_status',
    label: 'Cancelled',
    type: 'dot',
    map: {
      cancelled: 'danger',
    },
    visibility: ['view'],
    filter: {
      key: 'isCancelled',
      type: 'select',
      options: [
        { value: true, text: 'Cancelled' },
      ],
    },
  },
]
