import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'

export default {
  namespaced: true,
  state: {
    tableData: get('postSharesTableData', []),
    tableSort: get('postSharesTableSort'),
    detailData: get('postSharesDetailData'),
    tablePagination: get('postSharesTablePagination'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      authorUser_avatar: item.authorUser.avatar,
      authorUser_nickname: item.authorUser.nickname,
      email: item.authorUser.email,
    })),
    tableParams: (state, getters, rootState) => ({
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      set('postSharesTableData', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('postSharesTablePagination', val)
      state.tablePagination = val
    },
    SET_DETAIL_DATA(state, val) {
      set('postSharesDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('postSharesTableSort', val)
      state.tableSort = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }, id) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get(`/v1/bo/users/posts/${id}/shares`, { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
  },
}
