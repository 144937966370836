export default {
  namespaced: true,
  state: {
    userData: [],
    loadingSubmit: false,
  },
  getters: {
  },
  mutations: {
    SET_USER_DATA(state, val) {
      state.userData = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getUserData({ commit }, keyword) {
      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 5,
          keyword,
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)
        })
    },
    transfer({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      const amount = data.type === 'deduct' ? (data.amount * -1) : data.amount

      return this._vm.$http.post('/v1/bo/users/wallets/add-credits', {
        ...data,
        amount,
        type: undefined,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', true)
        })
    },
  },
}
