export default [
  {
    key: 'id',
    type: 'human-id',
    label: 'Order Number',
  },
  {
    key: 'created_at',
    label: 'Requested at',
    type: 'datetime',
    filter: {
      startKey: 'start_date',
      endKey: 'end_date',
      type: 'datetime',
    },
  },
  {
    key: 'payment_status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      CREATED: 'warning',
      FAILED: 'danger',
      CREATED_CANCELLED: 'danger',
      SUCCEED: 'success',
    },
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator && data.creator.id ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'seller_user_email',
    type: 'email',
    label: 'Creator Email',
    visibility: ['view'],
  },
  {
    key: 'seller_user_profile.countryCode',
    type: 'country',
    label: 'Creator country',
    visibility: ['view'],
  },
  {
    key: 'price',
    label: 'Amount',
    type: {
      callback(data) {
        return data.currency === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency !== 'GANK_GOLD' ? '$' : undefined
      },
    },
  },
  {
    key: 'price_as_charged',
    label: 'Currency',
    type: {
      callback(data) {
        return data.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency_as_charged && data.currency_as_charged !== 'GANK_GOLD' ? `${data.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'ui_title',
    label: 'Title',
    sortable: false,
    visibility: ['view'],
  },
  {
    key: 'ui_description',
    label: 'Description',
    visibility: ['view'],
  },
  {
    key: 'payment_src',
    label: 'Payment method',
    type: 'badge',
    map: {
      WALLET_GANK_EARNING: 'success',
    },
  },
]
