import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/order'

export default {
  namespaced: true,
  state: {
    tableFields: get('orderTableFields', fields),
    tableData: [],
    tableSort: get('orderTableSort'),
    tableFilter: get('orderTableFilter', []),
    detailData: get('orderDetailData'),
    tablePagination: get('orderTablePagination'),
    tableKeyword: get('orderTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      type: item.isRenewal ? 'auto renewal' : 'one-time',
      payment_status: item.status,
      active_status: item.isActive ? 'active' : 'inactive',
      cancelled_status: item.isCancelled ? 'cancelled' : undefined,
      creator: {
        ...item.creator,
        fullName: [_get(item, 'creator.firstName'), _get(item, 'creator.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
      },
      subscriber: {
        ...item.subscriber,
        fullName: [_get(item, 'subscriber.firstName'), _get(item, 'subscriber.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
      },
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        type: state.detailData.isRenewal ? 'auto renewal' : 'one-time',
        payment_status: state.detailData.status,
        active_status: state.detailData.isActive ? 'active' : 'inactive',
        cancelled_status: state.detailData.isCancelled ? 'cancelled' : undefined,
        creator: {
          ...state.detailData.creator,
          fullName: [_get(state.detailData, 'creator.firstName'), _get(state.detailData, 'creator.lastName')]
            .filter(name => name)
            .join(' ')
            .replace('  ', ' '), // remove double space
        },
        subscriber: {
          ...state.detailData.subscriber,
          fullName: [_get(state.detailData, 'subscriber.firstName'), _get(state.detailData, 'subscriber.lastName')]
            .filter(name => name)
            .join(' ')
            .replace('  ', ' '), // remove double space
        },
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('orderTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('orderTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('orderTable', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('orderTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('orderTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('orderDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('orderTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('orderTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/orders', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/orders/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/orders-v2/${id}/status`, data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters

      return this._vm.$http.post('/v1/bo/users/export/orders', {
        ...params,
        statuses: params.statuses ? [params.statuses] : null,
        orderBy: [params.orderBy],
        page: undefined,
        perPage: undefined,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
