export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(_, params) {
      return this._vm.$http.get('/v1/bo/users/auto-complete/country', {
        params: {
          ids: params.ids,
          perPage: params.perPage || undefined,
          keyword: params.keyword || undefined,
        },
      })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.name,
        })))
        .catch(() => [])
    },
    searchCodes(_, params) {
      const code = typeof params.ids === 'string' ? params.ids.toUpperCase() : params.ids

      return this._vm.$http.get('/v1/bo/users/auto-complete/country', {
        params: {
          codes: code,
          perPage: params.perPage || undefined,
          keyword: params.keyword || undefined,
        },
      })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.name,
        })))
        .catch(() => [])
    },
  },
}
