import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/show-reel'

export default {
  namespaced: true,
  state: {
    tableFields: get('showReelTableFields', fields),
    tableData: [],
    tableSort: get('showReelTableSort'),
    tableFilter: get('showReelTableFilter', []),
    detailData: get('showReelDetailData'),
    tablePagination: get('showReelTablePagination'),
    tableKeyword: get('showReelTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('showReelTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('showReelTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('showReelTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('showReelTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('showReelDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('showReelTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('showReelTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/medias?includeUserGame=true', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/users/media/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
