export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: false,
  },
  {
    key: 'description',
    label: 'Description',
    sortable: false,
  },
  {
    key: 'sort',
    label: 'Sort',
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
  },
]
