import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/discrepancy'

export default {
  namespaced: true,
  state: {
    tableFields: get('discrepancyTableFields', fields),
    tableData: get('discrepancyTableData', []),
    tablePagination: get('discrepancyTablePagination'),
    tableKeyword: get('discrepancyTableKeyword'),
    tableSort: get('discrepancyTableSort'),
    tableFilter: get('discrepancyTableFilter', []),
    detailData: get('discrepancyDetailData'),
    userData: [],
    loadingTable: false,
    loadingSubmit: false,
    loadingDetail: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      id: item.user_id,
    })),
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      order_by: getters.tableSort,
      // page: _get(state, 'tablePagination.currentPage', 1),
      // per_page: rootState.appConfig.settings.tablePerPage,
    }),
    userTableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('discrepancyTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('discrepancyTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('discrepancyTableData', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('discrepancyTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('discrepancyTableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('discrepancyTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('discrepancyTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_DETAIL_DATA(state, val) {
      set('discrepancyDetailData', val)
      state.detailData = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    getTableData({
      state, commit, getters,
    }, force = false) {
      if (!force && state.tableData && state.tableData.length) {
        commit('SET_TABLE_PAGINATION', {
          currentPage: 1,
          totalPage: 1,
          perPage: state.tableData.length,
          totalData: state.tableData.length,
        })

        return
      }

      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v2/payment/user-info/discrepancy', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data || [])

          commit('SET_TABLE_PAGINATION', {
            currentPage: 1,
            totalPage: 1,
            perPage: data.length,
            totalData: data.length,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/wallets/transactions/detail?id=${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/wallets/transactions/${id}/statuses`, data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters

      return this._vm.$http.post('/v1/bo/users/export/transactions', {
        ...params,
        statuses: params.statuses ? [params.statuses] : null,
        orderBy: [params.orderBy],
        page: undefined,
        perPage: undefined,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    match({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v2/payment/user-info/discrepancy?user_id=${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    async getWalletTableData({ commit, getters }, walletId) {
      commit('SET_LOADING_TABLE', true)

      const { userTableParams: params } = getters
      await this._vm.$http.get('/v1/bo/users/transactions', {
        params: {
          ...params,
          walletID: walletId,
        },
      })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getUserData({ commit }, keyword) {
      if (!keyword) {
        commit('SET_USER_DATA', [])
        return null
      }

      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 6,
          keyword,
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)

          return userData
        })
    },
  },
}
