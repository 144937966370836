import { transactionFilterPaymentV2 } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'human-id',
    label: 'Order Number',
  },
  {
    key: 'userID',
    label: 'User',
    visibility: false,
    filter: {
      key: 'userID',
      title: 'name',
      type: 'profile',
      stateOptions: 'transaction.userData',
      actionSearch: 'transaction/getUserData',
      actionDetail: 'user/getUserData',
    },
  },
  {
    key: 'created_at',
    label: 'Created at',
    type: 'datetime',
    filter: {
      startKey: 'start_date',
      endKey: 'end_date',
      type: 'datetime',
    },
  },
  {
    key: 'payment_status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      CREATED: 'warning',
      FAILED: 'danger',
      CREATED_CANCELLED: 'danger',
      SUCCEED: 'success',
    },
  },
  {
    key: 'supporter.nickname',
    label: 'Supporter',
    type: 'profile',
    sortable: false,
    image: 'supporter.avatar',
    url: {
      callback(data) {
        return data.supporter && data.supporter.id ? `/user/${data.supporter.id}` : null
      },
    },
  },
  {
    key: 'supporter.email',
    type: 'email',
    label: 'Supporter email',
    visibility: ['view'],
  },
  {
    key: 'creator.nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator.avatar',
    url: {
      callback(data) {
        return data.creator && data.creator.id ? `/user/${data.creator.id}` : null
      },
    },
  },
  {
    key: 'seller_user_email',
    type: 'email',
    label: 'Creator Email',
    visibility: ['view'],
  },
  {
    key: 'seller_user_profile.countryCode',
    type: 'country',
    label: 'Creator country',
    visibility: ['view'],
  },
  {
    key: 'usecase',
    label: 'Usecase',
    type: 'dot',
    map: {},
    sortable: false,
    filter: {
      key: 'usecases',
      type: 'multiple',
      options: [
        { value: 'DONATION', text: 'Donation' },
        { value: 'WITHDRAWAL', text: 'Withdrawal' },
        { value: 'MERCHANDISE', text: 'Merchandise' },
        { value: 'TOPUP', text: 'Topup' },
        { value: 'USER_TIER_ONETIME', text: 'User tier onetime' },
        { value: 'USER_TIER_RECURRING', text: 'User tier recurring' },
        { value: 'DIGITAL_GOODS', text: 'Digital goods' },
        { value: 'SERVICE', text: 'Service' },
        { value: 'SERVICE_COMPLETE', text: 'Service complete' },
        { value: 'TOPUP_REDEEM', text: 'Topup redeem' },
        { value: 'MERCHANDISE_COMPLETE', text: 'Merchandise complete' },
        { value: 'USER_MEMBERSHIP_ONETIME', text: 'User membership onetime' },
        { value: 'USER_MEMBERSHIP_RECURRING', text: 'User membership recurring' },
        { value: 'INTERNAL', text: 'Internal' },
        { value: 'WEBHOOK', text: 'Webhook' },
        { value: 'SERVICE_REFUND', text: 'Service refund' },
        { value: 'MERCHANDISE_REFUND', text: 'Merchandise refund' },
        { value: 'EVENT', text: 'Event' },
        { value: 'PAY_PER_VIEW', text: 'Pay per view' },
        { value: 'REFERRAL_REWARD', text: 'Referral reward' },
        { value: 'MIGRATION_V1', text: 'Migration v1' },
      ],
    },
  },
  {
    key: 'ui_title',
    label: 'Title',
    sortable: false,
    visibility: ['view'],
  },
  {
    key: 'ui_description',
    label: 'Description',
    visibility: ['view'],
  },
  // all value is in usd or gold
  {
    key: 'price',
    label: 'Amount',
    type: {
      callback(data) {
        return data.currency === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency !== 'GANK_GOLD' ? '$' : undefined
      },
    },
  },
  {
    key: 'price_as_charged',
    label: 'Currency',
    type: {
      callback(data) {
        return data.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.currency_as_charged && data.currency_as_charged !== 'GANK_GOLD' ? `${data.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'fee.price_as_charged_applied_fee',
    label: 'Applied Fee',
    type: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged && data.fee.currency_as_charged !== 'GANK_GOLD' ? `${data.fee.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  {
    key: 'fee.price_as_charged_conversion_fee',
    label: 'Conversion Fee',
    type: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged === 'GANK_GOLD' ? 'gold' : undefined
      },
    },
    prefix: {
      callback(data) {
        return data.fee && data.fee.currency_as_charged && data.fee.currency_as_charged !== 'GANK_GOLD' ? `${data.fee.currency_as_charged.toUpperCase()} ` : undefined
      },
    },
  },
  transactionFilterPaymentV2,
  {
    key: 'transfer_type',
    label: 'Type',
    type: 'badge',
    visibility: ['view'],
    map: {
      DEPOSIT: 'success',
      CREDIT: 'warning',
    },
  },
  {
    key: 'payment_id',
    label: 'Reference ID',
    visibility: ['view'],
  },
]
