import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/subscription'

export default {
  namespaced: true,
  state: {
    tableFields: get('subscriptionTableFields', fields),
    tableData: [],
    tableSort: get('subscriptionTableSort'),
    tableFilter: get('subscriptionTableFilter', []),
    detailData: get('subscriptionDetailData'),
    tablePagination: get('subscriptionTablePagination'),
    tableKeyword: get('subscriptionTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      type: item.isRenewal ? 'auto renewal' : 'one-time',
      payment_status: item.status,
      active_status: item.isActive ? 'active' : 'inactive',
      cancelled_status: item.isCancelled ? 'cancelled' : undefined,
      creator: {
        ...item.creator,
        fullName: [_get(item, 'creator.firstName'), _get(item, 'creator.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
      },
      subscriber: {
        ...item.subscriber,
        fullName: [_get(item, 'subscriber.firstName'), _get(item, 'subscriber.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
      },
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('subscriptionTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('subscriptionTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('subscriptionTable', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('subscriptionTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('subscriptionTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('subscriptionDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('subscriptionTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('subscriptionTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/subscriptions', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    cancel({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`/v1/users/subscription/${id}`, {
        isCancelled: true,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    reactivate({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`/v1/users/subscription/${id}`, {
        isCancelled: false,
        isRenewal: true,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    deactivate({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`/v1/users/subscription/${id}`, {
        isCancelled: true,
        isActive: false,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters

      return this._vm.$http.post('/v1/bo/users/export/subscriptions', {
        ...params,
        orderBy: [params.orderBy],
        page: undefined,
        perPage: undefined,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
