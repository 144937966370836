import moment from 'moment'

export default {
  namespaced: true,
  state: {
    appSetting: undefined,
    loadingLoad: false,
    loadingSubmit: false,
  },
  getters: {
    appSettingData: state => ({
      'iOs App ID': state.appSetting.appStoreUrl,
      'Android App ID': state.appSetting.playStoreUrl,
      'Update Title': state.appSetting.updateTitle,
      'Update Description': state.appSetting.updateDescription,
      'Minimum version': state.appSetting.minimumVersion,
      'Current version': state.appSetting.currentVersion,
      'Minimum version iOS': state.appSetting.minimumVersionIos,
      'Current version iOs': state.appSetting.currentVersionIos,
      'Disable Prompt': state.appSetting.disablePrompt,
      'Force Update': state.appSetting.forceUpdate,
      'Force Update iOS': state.appSetting.forceUpdateIos,
      'Update At': moment(state.appSetting.updatedAt).format('MMM D, YYYY HH:mm A'),
    }),
  },
  mutations: {
    SET_APP_SETTING(state, val) {
      state.appSetting = val
    },
    SET_LOADING_LOAD(state, val) {
      state.loadingLoad = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getAppSetting({ commit }) {
      commit('SET_LOADING_LOAD', true)

      this._vm.$http.get('/v1/settings/app-updates')
        .then(({ data }) => {
          commit('SET_APP_SETTING', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_LOAD', false)
        })
    },
    update({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      this._vm.$http.post('/v1/settings/app-updates', data)
        .then(({ res }) => {
          commit('SET_APP_SETTING', res.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
