export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'gold',
  },
  {
    key: 'numOfVoucher',
    label: 'Total Voucher',
  },
  {
    key: 'limitUsage',
    label: 'Limit Usage',
  },
  {
    key: 'startDate',
    label: 'Start Date',
    type: 'datetime',
  },
  {
    key: 'endDate',
    label: 'End Date',
    type: 'datetime',
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
  },
]
