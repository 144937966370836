export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'backgroundImage',
    label: 'Image',
    type: 'image',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'subtitle',
    label: 'Subtitle',
  },
  {
    key: 'rule.listType',
    label: 'Type',
    type: 'dot',
    map: {
      profile: 'primary',
      listing: 'success',
      service: 'success',
    },
  },
  {
    key: 'users',
    label: 'Avatars',
    type: 'avatars',
    titleKey: 'nickname',
    imageKey: 'avatar',
  },
  {
    key: 'services',
    label: 'Services',
    type: 'avatars',
    titleKey: 'name',
    imageKey: 'game.image',
  },
  {
    key: 'rule.countries',
    label: 'Countries',
    type: 'countries',
  },
  // countries
  {
    key: 'sort',
    label: 'Sort',
    prefix: '#',
  },
  {
    key: 'createdAt',
    label: 'Created at',
    type: 'datetime',
  },
  {
    key: 'updatedAt',
    label: 'Updated at',
    type: 'datetime',
  },
]
