import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    list: get('languageList', []),
    loadingList: false,
  },
  getters: {
  },
  mutations: {
    SET_LIST(state, val) {
      set('languageList', val)
      state.list = val
    },
    SET_LOADING_LIST(state, val) {
      state.loadingList = val
    },
  },
  actions: {
    getList({ commit, state }, code) {
      // Assumption: languages data will not going to change
      if (state.list.length) {
        return
      }

      commit('SET_LOADING_LIST', true)

      const params = { code }
      this._vm.$http.get('/v1/settings/languages', { params })
        .then(({ data }) => {
          commit('SET_LIST', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_LIST', false)
        })
    },
  },
}
