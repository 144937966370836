<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { isUserLoggedIn } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import {
  superAdminAbility,
  financeAbility,
  marketerAbility,
  moderatorAbility,
  adminAbility,
  customerAbility,
} from '@/libs/acl/config'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: 'Admin - %s',
    }
  },
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    title() {
      return this.$route.meta.pageTitle ?? 'Gank'
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    // watch $route changes to record previous path
    // needed for 'resetTableParams': 'page' to work
    $route: {
      immediate: false,
      handler(_, from) {
        // do not record 'from' route on page reload
        if (from.name && from.matched.length) {
          this.$store.commit('appConfig/UPDATE_FROM_PATH', from.path)
        }
      },
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  async created() {
    // get login status
    const isLoggedIn = await isUserLoggedIn()

    if (isLoggedIn) {
      // get current active user data
      const { data: userData } = await useJwt.user()

      // get user roles
      const { roles } = userData.data

      // assign abilities or permissions based on user roles
      let ability = []
      if (roles.includes('super-admin')) {
        ability = superAdminAbility
      } else if (roles.includes('administrator')) {
        ability = adminAbility
      } else if (roles.includes('admin')) {
        ability = adminAbility
      } else if (roles.includes('finance')) {
        ability = financeAbility
      } else if (roles.includes('marketer')) {
        ability = marketerAbility
      } else if (roles.includes('moderator')) {
        ability = moderatorAbility
      } else if (roles.includes('customer')) {
        ability = customerAbility
      }

      // put the ability data into the user data
      // because acl/ability.js authorize based on the user.ability
      const updatedUserData = { ...userData.data, ability }
      // save the user data to vuex
      await this.$store.commit('auth/UPDATE_USER_DATA', updatedUserData)

      // apply abilities
      this.$ability.update(ability)
      this.$nextTick(this.$forceUpdate)

      // record current route as 'from' route on page reload
      this.setUnloadListener()

      // get pending data count
      this.$store.dispatch('dashboard/getPendingCount')
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    setUnloadListener() {
      // record current route as 'from' route on page reload
      window.onbeforeunload = () => {
        this.$store.commit('appConfig/UPDATE_FROM_PATH', this.$route.path)
      }
    },
  },
}
</script>
