export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'user_nickname',
    label: 'User',
    type: 'profile',
    image: 'user_avatar',
    url: {
      callback(data) {
        return `/user/${data.user_id}`
      },
    },
  },
  {
    key: 'user_email',
    type: 'email',
    label: 'Email',
    // visibility: ['view'],
  },
  {
    key: 'v1_balance_earning',
    label: 'Balance earning (V1)',
  },
  {
    key: 'v2_balance_earning',
    label: 'Balance earning',
  },
  {
    key: 'v1_balance_gold',
    label: 'Balance gold (V1)',
  },
  {
    key: 'v2_balance_gold',
    label: 'Balance gold',
  },
]
