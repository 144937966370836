export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Requested Time',
    type: 'datetime',
  },
  {
    key: 'adminNickname',
    image: 'avatar',
    label: 'Requested By',
    type: 'profile',
    url: {
      callback(data) {
        return `/user/${data.adminUserID}`
      },
    },
  },
  {
    key: 'status',
    type: 'dot',
    label: 'Status',
    map: {
      pending: 'warning',
      succeeded: 'success',
      failed: 'danger',
    },
  },
  {
    key: 'file_name',
    label: 'File Name',
    visibility: ['view'],
  },
  {
    key: 'tableType',
    label: 'Source',
  },
  {
    key: 'query',
    label: 'Query',
    type: 'meta',
    visibility: ['view'],
  },
]
