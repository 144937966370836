export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'thumbUrl',
    label: 'Media',
    type: 'image',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'userID',
    label: 'User',
  },
  {
    key: 'userGameID',
    label: 'Game',
  },
  {
    key: 'type',
    label: 'Type',
  },
]
