export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'code',
    label: 'Name',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'gold',
  },
  {
    key: 'totalUsage',
    label: 'Total Usage',
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    map: {
      used: 'secondary',
      available: 'success',
    },
  },
  {
    key: 'startDate',
    label: 'Start Date',
    type: 'datetime',
  },
  {
    key: 'endDate',
    label: 'End Date',
    type: 'datetime',
  },
]
