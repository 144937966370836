import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableSort: get('serviceTableSort'),
    tableFilter: get('serviceTableFilter', []),
    detailData: get('serviceDetailData'),
    tablePagination: get('serviceTablePagination'),
    tableKeyword: get('serviceTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => {
      const catalogMap = {
        service: 'Service',
        product: 'Digital goods',
        physical: 'Merchandise',
      }

      return ({
        ...item,
        thumbUrl: item.media && item.media.length > 0 ? item.media[0].thumbUrl : null,
        catalog_name: catalogMap[item.catalog],
        category_name: item.category ? item.category.name : null,
        game_name: item.game ? item.game.name : null,
        status: item.isActive ? 'active' : 'inactive',
        review: {
          count: item.summary.totalReviews,
          star: item.summary.totalStars,
        },
      })
    }),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters) => ({
      ...getters.tableFilter,
      keyword: state.tableKeyword,
      orderBy: getters.tableSort,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      const catalogMap = {
        service: 'Service',
        product: 'Digital goods',
        physical: 'Merchandise',
      }

      return {
        ...state.detailData,
        thumbUrl: state.detailData.media.length ? state.detailData.media[0].thumbUrl : null,
        category_name: state.detailData.category ? state.detailData.category.name : null,
        catalog_name: catalogMap[state.detailData.catalog],
        game_name: state.detailData.game ? state.detailData.game.name : null,
        review: {
          count: state.detailData.summary.totalReviews,
          star: state.detailData.summary.totalStars,
        },
      }
    },
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('serviceTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('serviceDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('serviceTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('serviceTableFilter', val)
      state.tableFilter = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('serviceTablePagination', val)
      state.tablePagination = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/services', { params }) // TODO: ERROR: relation \"catalogs.medias\" does not exist (SQLSTATE 42P01)
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/catalogs/services/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/catalogs/services/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/catalogs/services', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/catalogs/services/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    search(_, params) {
      return this._vm.$http.get('/v1/bo/users/auto-complete/service', { params })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.name,
        })))
        .catch(() => [])
    },
  },
}
