import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableSort: get('reviewTableSort'),
    tableFilter: get('reviewTableFilter', []),
    detailData: get('reviewDetailData'),
    tablePagination: get('reviewTablePagination'),
    tableKeyword: get('reviewTableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      avatar: item.userData.avatar,
      fullName: [item.userData.nickname]
        .filter(name => name)
        .join(' ')
        .replace('  ', ' ')
        .trim(), // remove double space
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        avatar: _get(state, 'detailData.userData.avatar'),
        fullName: [_get(state, 'detailData.userData.nickname')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
      }
    },
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('reviewTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('reviewTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('reviewDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('reviewTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('reviewTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/reviews', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/catalogs/services/reviews/${id}`) // TODO: 404
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/catalogs/services/reviews/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
