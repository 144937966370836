export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Order Created',
    type: 'datetime',
  },
  {
    key: 'code',
    label: 'Order Number',
    visibility: ['view'],
  },
  {
    key: 'userData.nickname',
    label: 'Buyer',
    type: 'profile',
    image: 'userData.avatar',
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.userID}`
      },
    },
  },
  {
    key: 'userData.email',
    type: 'email',
    label: 'Buyer email',
    visibility: ['view'],
  },
  {
    key: 'price',
    label: 'Amount',
    type: 'gold',
  },
  {
    key: 'coachData.nickname',
    label: 'Seller',
    type: 'profile',
    image: 'coachData.avatar',
    sortable: false,
    visibility: ['view'],
    url: {
      callback(data) {
        return `/user/${data.coachID}`
      },
    },
  },
  {
    key: 'coachData.email',
    type: 'email',
    label: 'Seller email',
    visibility: ['view'],
  },
  {
    key: 'service.name',
    label: 'Service',
    type: 'profile',
    image: 'service.media[0].thumbUrl',
    url: {
      callback(data) {
        return `/service/${data.service.id}`
      },
    },
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      completed: 'success',
      started: 'success',
      start_pending: 'warning',
      start_cancelled: 'danger',
      cancelled: 'danger',
    },
  },
  {
    key: 'updatedAt',
    label: 'Order Updated',
    type: 'datetime',
  },
]
