export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'desktopImages[0].url',
    label: 'Image (Desktop)',
    type: 'image',
  },
  {
    key: 'mobileImages[0].url',
    label: 'Image (Mobile)',
    type: 'image',
  },
  {
    key: 'placeToDisplay',
    label: 'Places',
    type: 'dot',
    map: {
      discover_page: 'primary',
      home_page: 'primary',
    },
  },
  {
    key: 'countryCode',
    label: 'Country',
    type: 'country',
  },
]
