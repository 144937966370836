import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/subscriptionV2'

export default {
  namespaced: true,
  state: {
    tableFields: get('subscriptionV2TableFields', fields),
    tableData: [],
    tableSort: get('subscriptionV2TableSort'),
    tableFilter: get('subscriptionV2TableFilter', []),
    detailData: get('subscriptionV2DetailData'),
    tablePagination: get('subscriptionV2TablePagination'),
    tableKeyword: get('subscriptionV2TableKeyword'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: (state, getters) => state.tableData.map(item => ({
      ...item,
      id: item.human_id,
      status: getters.tableParams.usecases === 'GANK_CREATOR_MEMBERSHIPS_ACTIVE_V2_ADMIN'
        ? 'active'
        : 'inactive',
    })),
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      per_page: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        id: state.detailData.human_id,
        status: state.detailData.usecases === 'GANK_CREATOR_MEMBERSHIPS_ACTIVE_V2_ADMIN'
          ? 'active'
          : 'inactive',
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('subscriptionV2TableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('subscriptionV2TableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      set('subscriptionV2Table', val)
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('subscriptionV2TablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('subscriptionV2TableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('subscriptionV2DetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('subscriptionV2TableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('subscriptionV2TableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    async getTableData({
      state, commit, getters, rootState,
    }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v2/payment/user-info/admin', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.current_creator_memberships_v2.data || [])
          commit('SET_TABLE_PAGINATION', {
            currentPage: state.tablePagination?.currentPage ?? 1,
            totalPage: data.current_creator_memberships_v2.count / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
            perPage: state.tablePagination?.per_page ?? rootState.appConfig.settings.tablePerPage,
            totalData: data.current_creator_memberships_v2.count,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit, dispatch }, { id, usecases = null }) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get('/v2/payment/user-info/admin', {
        params: {
          search_keywords: id,
          usecases: usecases || 'GANK_CREATOR_MEMBERSHIPS_ACTIVE_V2_ADMIN',
        },
      })
        .then(({ data }) => {
          if (data.current_creator_memberships_v2.data.length) {
            const detail = {
              ...data.current_creator_memberships_v2.data[0],
              usecases: usecases || 'GANK_CREATOR_MEMBERSHIPS_ACTIVE_V2_ADMIN',
            }

            commit('SET_DETAIL_DATA', detail)
            return detail
          }

          if (usecases === null) {
            return dispatch('getDetail', { id, usecases: 'GANK_CREATOR_MEMBERSHIPS_INACTIVE_V2_ADMIN' })
          }

          commit('SET_DETAIL_DATA', null)
          return null
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    cancel({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v2/payment/subscriptions/admin?human_id=${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    reactivate({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v2/payment/subscriptions/admin?human_id=${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    deactivate({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`/v1/users/subscription/${id}`, {
        isCancelled: true,
        isActive: false,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters
      const payload = {
        ...params,
        search_keywords: undefined,
        searchKeywords: params.search_keywords,
        orderBy: params.orderBy,
        per_page: undefined,
        perPage: Number(params.per_page),
      }
      return this._vm.$http.post('/v1/bo/users/export/subscriptions', payload)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
