import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableSort: get('GameTableSort'),
    tableFilter: get('GameTableFilter', []),
    detailData: get('GameDetailData'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => state.tableData.map(item => ({
      ...item,
      fullName: [item.firstName, item.lastName]
        .filter(name => name)
        .join(' ')
        .replace('  ', ' ')
        .trim(), // remove double space
      active_status: item.isActive ? 'active' : 'inactive',
      verified_status: item.verified ? 'verified' : 'unverified',
      gameSpecsName: item.gameSpecs ? item.gameSpecs.map(el => el.name) : [],
    })),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        fullName: [_get(state, 'detailData.firstName'), _get(state, 'detailData.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
        active_status: _get(state, 'detailData.active') ? 'active' : 'inactive',
        verified_status: _get(state, 'detailData.verified') ? 'verified' : 'unverified',
        specialization: _get(state, 'detailData.gameSpecs'),
      }
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (_, getters) => ({
      ...getters.tableFilter,
      orderBy: getters.tableSort,
    }),
  },
  mutations: {
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_DETAIL_DATA(state, val) {
      set('GameDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('GameTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('GameTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/games', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/bo/users/games/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/bo/users/games/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/bo/users/games', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    search(_, params) {
      return this._vm.$http.get('/v1/bo/users/games', { params })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.name,
        })))
        .catch(() => [])
    },
  },
}
