export default {
  namespaced: true,
  state: {
    data: [],
    loadingDetail: false,
    countryCode: ['ID', 'MY', 'SG', 'PH', 'TH', 'VN'],
  },
  getters: {

  },
  mutations: {
    RESET_DATA(state) {
      state.data = []
    },
    SET_DATA(state, val) {
      state.data.push(val)
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
  },
  actions: {
    getData({ commit }, country) {
      commit('RESET_DATA')
      commit('SET_LOADING_DETAIL', true)

      country.forEach(el => {
        this._vm.$http.get(`/v1/wallets/packages/donation/checkout-summary?countryCode=${el}`)
          .then(({ data }) => {
            commit('SET_DATA', data.data)
          })
          .finally(() => {
            commit('SET_LOADING_DETAIL', false)
          })
      })
    },
  },
}
