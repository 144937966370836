import Vue from 'vue'
import moment from 'moment'

Vue.filter('number', value => Number(value).toLocaleString())
Vue.filter('datetime', value => moment.utc(value).local().format('DD/MM/YYYY HH:mm'))
Vue.filter('dateShort', value => moment.utc(value).local().format('MMM Do'))
Vue.filter('dateShortYear', value => moment.utc(value).local().format('MMM DD, YYYY'))

Vue.filter('thumbnail', (value, size = 32) => {
  let image = value

  // check whether the image is empty or from source other than google CDN
  if (!image || !image.includes('googleusercontent')) {
    return image
  }

  // check whether the image resized already
  if (image.includes('=s')) {
    [image] = image.split('=s')
  }

  return `${image}=s${size}-c`
})

Vue.filter('table-filter', (value, size = 32) => {
  let image = value

  // check whether the image is empty or from source other than google CDN
  if (!image || !image.includes('googleusercontent')) {
    return image
  }

  // check whether the image resized already
  if (image.includes('=s')) {
    [image] = image.split('=s')
  }

  return `${image}=s${size}-c`
})
