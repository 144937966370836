export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'featuredImage',
    label: 'Cover',
    type: 'image',
  },
  {
    key: 'name',
    label: 'Title',
    type: 'profile',
    image: 'thumbImage',
  },
  {
    key: 'type',
    label: 'Type',
    type: 'dot',
    visibility: ['view'],
    map: {
      sign_up: 'warning',
    },
  },
  {
    key: 'status',
    label: 'Status',
    type: 'badge',
    map: {
      published: 'success',
      waiting: 'warning',
      completed: 'success',
      canceled: 'danger',
    },
  },
  {
    key: 'cost',
    label: 'Cost',
    type: 'gold',
  },
  {
    key: 'sort',
    label: 'Sort',
    visibility: ['view'],
  },
  {
    key: 'slug',
    label: 'Slug',
    visibility: ['view'],
  },
  {
    key: 'startDate',
    label: 'Start Date',
    type: 'datetime',
  },
  {
    key: 'endDate',
    label: 'End Date',
    type: 'datetime',
  },
  //
]
