// import { transactionFilterPaymentV2 } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'human-id',
    label: 'Order Number',
  },
  {
    key: 'supporter_nickname',
    label: 'Supporter',
    type: 'profile',
    sortable: false,
    image: 'supporter_avatar',
    url: {
      callback(data) {
        return data.supporter_user_id ? `/user/${data.supporter_user_id}` : null
      },
    },
  },
  {
    key: 'creator_nickname',
    label: 'Creator',
    type: 'profile',
    sortable: false,
    image: 'creator_avatar',
    url: {
      callback(data) {
        return data.creator_user_id ? `/user/${data.creator_user_id}` : null
      },
    },
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    map: {
      active: 'success',
      inactive: 'danger',
    },
  },
  {
    key: 'membership_title',
    label: 'Membership',
    type: 'profile',
    sortable: false,
    image: 'membership_image_url',
  },
  {
    key: 'subscription_start_date',
    label: 'Start Date',
    type: 'datetime',
  },
  {
    key: 'subscription_end_date',
    label: 'End Date',
    type: 'datetime',
  },
  // {
  //   key: 'subscription_total_price',
  //   label: 'Total Price',
  //   visibility: ['view'],
  //   prefix: {
  //     callback(data) {
  //       return data.subscription_total_currency ? `${data.subscription_total_currency} ` : undefined
  //     },
  //   },
  // },
  {
    key: 'subscription_type_latest',
    label: 'Latest Subscription Type',
    type: 'boolean',
  },
  {
    key: 'subscription_recurring_cancelled',
    label: 'Recurring Cancelled',
    type: 'boolean',
    visibility: ['view'],
  },
]
