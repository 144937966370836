export default [
  {
    id: '8e4fc437-ecfa-4a75-9c1a-05109a5eec57',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PH',
    text: 'Philippines',
    region: 'Asia',
  },
  {
    id: '28c62b3e-5421-4b32-9147-4458b14be2bd',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TH',
    text: 'Thailand',
    region: 'Asia',
  },
  {
    id: 'bd6bdb3e-2332-48a8-bbf3-cfa35d1de485',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MY',
    text: 'Malaysia',
    region: 'Asia',
  },
  {
    id: '77947107-1678-40b1-add8-23b1a9b5b152',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SG',
    text: 'Singapore',
    region: 'Asia',
  },
  {
    id: '0208bc0e-6d67-4e1c-9edb-6eabf1d9af83',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ID',
    text: 'Indonesia',
    region: 'Asia',
  },
  {
    id: 'b72fc42c-cd82-45b6-ab3c-37fdd1720d06',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AF',
    text: 'Afghanistan',
    region: 'Asia',
  },
  {
    id: '38d2796a-1454-4869-a99e-1f8d21d2174c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AL',
    text: 'Albania',
    region: 'Europe',
  },
  {
    id: '8807458b-b256-4013-8827-1c753119b672',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DZ',
    text: 'Algeria',
    region: 'Africa',
  },
  {
    id: '21f07300-8794-4690-8e07-9a24768cb0a6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AS',
    text: 'American Samoa',
    region: 'Oceania',
  },
  {
    id: '2776cb38-1b13-4031-aa47-2c7a0ecad174',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AD',
    text: 'Andorra',
    region: 'Europe',
  },
  {
    id: '33c6b533-b43e-4a06-b042-4cca010bcd38',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AO',
    text: 'Angola',
    region: 'Africa',
  },
  {
    id: '78eb53ca-73bb-4829-a6ef-861a0824f0b0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AI',
    text: 'Anguilla',
    region: 'Americas',
  },
  {
    id: 'b0a6e068-3bd1-4e32-ba11-0e968fdca0bc',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AG',
    text: 'Antigua and Barbuda',
    region: 'Americas',
  },
  {
    id: '1357d3dd-b07a-43c7-bc1b-2ec283e49a81',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AR',
    text: 'Argentina',
    region: 'Americas',
  },
  {
    id: 'cd100f59-65c1-4399-931c-337ed5c722ae',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AM',
    text: 'Armenia',
    region: 'Asia',
  },
  {
    id: '01417663-a26e-43ce-8b4a-918d1163fc2c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AW',
    text: 'Aruba',
    region: 'Americas',
  },
  {
    id: 'a77d16ea-aa23-4695-8115-b5cb4e64965b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AU',
    text: 'Australia',
    region: 'Oceania',
  },
  {
    id: '61cd6ce6-6403-492c-bb90-e7670c06b06a',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AT',
    text: 'Austria',
    region: 'Europe',
  },
  {
    id: 'c7bf40b8-e81d-4337-9f51-43d3643d242c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AZ',
    text: 'Azerbaijan',
    region: 'Asia',
  },
  {
    id: '9b707ba8-42ec-4ad3-baf3-09085059937e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BS',
    text: 'Bahamas',
    region: 'Americas',
  },
  {
    id: 'c6f1d28c-da66-4a17-8b2d-ecf2c73db7a0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BH',
    text: 'Bahrain',
    region: 'Asia',
  },
  {
    id: '0d9baace-98de-4e0d-9476-fcbe0e6dadcb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BD',
    text: 'Bangladesh',
    region: 'Asia',
  },
  {
    id: '7cdbe853-2021-436b-aa1e-addf1da88e92',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BB',
    text: 'Barbados',
    region: 'Americas',
  },
  {
    id: '96e8ceb3-79b4-4f24-8341-b92c04c12003',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BY',
    text: 'Belarus',
    region: 'Europe',
  },
  {
    id: 'f0eb6fd5-03d1-4a28-a0ba-97df7dd9273b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BE',
    text: 'Belgium',
    region: 'Europe',
  },
  {
    id: '001cc983-82e0-4a4d-8bb9-2f8fb273bc77',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BZ',
    text: 'Belize',
    region: 'Americas',
  },
  {
    id: 'a9155230-909b-4f45-ae9b-1b2e59b51b58',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BJ',
    text: 'Benin',
    region: 'Africa',
  },
  {
    id: '3d14c004-be86-4497-8c5e-250647b60f19',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BM',
    text: 'Bermuda',
    region: 'Americas',
  },
  {
    id: 'e91416b9-eea5-4f1a-8c9a-e241c89e1dd3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BT',
    text: 'Bhutan',
    region: 'Asia',
  },
  {
    id: '3a9e9dac-d125-476a-9aa9-a81765d22513',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BO',
    text: 'Bolivia (Plurinational State of)',
    region: 'Americas',
  },
  {
    id: '35f78a63-0564-4d4b-be9b-1d155fdbae60',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BQ',
    text: 'Bonaire, Sint Eustatius and Saba',
    region: 'Americas',
  },
  {
    id: '8694a0e7-3510-419b-b8b3-c793f8677bb7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BA',
    text: 'Bosnia and Herzegovina',
    region: 'Europe',
  },
  {
    id: '75fd39c6-0d64-4d4b-b03d-781bff2c28b3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BW',
    text: 'Botswana',
    region: 'Africa',
  },
  {
    id: 'dd96d585-4fd4-466b-99c1-66f1b18f5988',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BV',
    text: 'Bouvet Island',
    region: 'Americas',
  },
  {
    id: '507ac5d1-bda7-496c-9711-cd774180e4e1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BR',
    text: 'Brazil',
    region: 'Americas',
  },
  {
    id: '92acaa2b-b4d2-40bb-b3d4-bbf0fb371935',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IO',
    text: 'British Indian Ocean Territory',
    region: 'Africa',
  },
  {
    id: 'd680647e-ba9b-4435-b2d5-dfb57dde00f5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BN',
    text: 'Brunei Darussalam',
    region: 'Asia',
  },
  {
    id: 'c8340bf8-fa9c-4047-a74c-cecc6054f1be',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BG',
    text: 'Bulgaria',
    region: 'Europe',
  },
  {
    id: 'd80314c2-a8cf-4038-9550-bee6826bc2a7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BF',
    text: 'Burkina Faso',
    region: 'Africa',
  },
  {
    id: 'aa0ab758-7f8a-4532-a2f2-b2adc5f3c74b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BI',
    text: 'Burundi',
    region: 'Africa',
  },
  {
    id: 'c4135605-fb68-42f1-928d-6a9276e1f7e5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CV',
    text: 'Cabo Verde',
    region: 'Africa',
  },
  {
    id: 'ce11a7f5-3ac5-4591-8830-94eef286f945',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KH',
    text: 'Cambodia',
    region: 'Asia',
  },
  {
    id: '42266a20-293a-45a3-ad24-904593104f9d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CM',
    text: 'Cameroon',
    region: 'Africa',
  },
  {
    id: 'c549d0bf-f293-4065-918b-00003d9bca78',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CA',
    text: 'Canada',
    region: 'Americas',
  },
  {
    id: '58057211-3267-4a58-8a32-16369350d648',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KY',
    text: 'Cayman Islands',
    region: 'Americas',
  },
  {
    id: 'dc148fd9-a4f0-44bb-9439-17f165946bcc',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CF',
    text: 'Central African Republic',
    region: 'Africa',
  },
  {
    id: '78cef008-46a9-46c0-87cc-474d1a04e54a',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TD',
    text: 'Chad',
    region: 'Africa',
  },
  {
    id: '56d982ca-a4ed-4353-96d2-4bc5e4994f72',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CL',
    text: 'Chile',
    region: 'Americas',
  },
  {
    id: '1edc7b3f-51b3-4705-965d-b0b865a545c7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CN',
    text: 'China',
    region: 'Asia',
  },
  {
    id: 'd8b6aa9b-7bc4-4a2a-a3fd-25374753d596',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CX',
    text: 'Christmas Island',
    region: 'Oceania',
  },
  {
    id: 'f98fae04-49ea-48e2-b189-fc9e0d07b0d1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CC',
    text: 'Cocos (Keeling) Islands',
    region: 'Oceania',
  },
  {
    id: '772f0a90-60ec-45d2-bbdc-018d5736a355',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CO',
    text: 'Colombia',
    region: 'Americas',
  },
  {
    id: '7246a29b-55fe-44ff-8bad-a86ba99f6cb8',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KM',
    text: 'Comoros',
    region: 'Africa',
  },
  {
    id: '9747302c-8648-42cf-8caa-97051e07bc42',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CG',
    text: 'Congo',
    region: 'Africa',
  },
  {
    id: '96417e63-6264-48ba-9e5f-63459b588b87',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CD',
    text: 'Congo, Democratic Republic of the',
    region: 'Africa',
  },
  {
    id: '89664c40-3dba-4d8a-8f87-0c1d193c659b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CK',
    text: 'Cook Islands',
    region: 'Oceania',
  },
  {
    id: 'd757e4be-2821-4bfc-b34e-192857354ddb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CR',
    text: 'Costa Rica',
    region: 'Americas',
  },
  {
    id: 'd8e5cbf4-698e-48e8-9db7-ee461b7cbba1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CI',
    text: "Côte d'Ivoire",
    region: 'Africa',
  },
  {
    id: 'a83e5245-355b-4a03-a759-4cd8e58bc140',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HR',
    text: 'Croatia',
    region: 'Europe',
  },
  {
    id: 'fdb89e36-ca27-405e-b380-ad2db482ec21',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CU',
    text: 'Cuba',
    region: 'Americas',
  },
  {
    id: '9c08b604-6d90-45ae-848a-ecf4832ebefb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CW',
    text: 'Curaçao',
    region: 'Americas',
  },
  {
    id: '113e8ddc-aef3-4b0a-9db2-1c5823d92172',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CY',
    text: 'Cyprus',
    region: 'Asia',
  },
  {
    id: '50d0ca74-0e2a-437e-9fc1-7a8e8971a8d1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CZ',
    text: 'Czechia',
    region: 'Europe',
  },
  {
    id: 'fe6f6281-bf79-4135-85cd-5f4d25471cc1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DK',
    text: 'Denmark',
    region: 'Europe',
  },
  {
    id: '442c0312-537d-47a9-90b8-411711fa6269',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DJ',
    text: 'Djibouti',
    region: 'Africa',
  },
  {
    id: '784cac94-f126-4f7e-9e16-1a7423274a31',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DM',
    text: 'Dominica',
    region: 'Americas',
  },
  {
    id: '82ae18b6-d5b8-4bf6-a516-d2fbf9b91888',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DO',
    text: 'Dominican Republic',
    region: 'Americas',
  },
  {
    id: '93387069-1041-4aa1-9baa-a5219873234e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'EC',
    text: 'Ecuador',
    region: 'Americas',
  },
  {
    id: '83527a9a-fbbc-4809-a37e-b3d936cf00bc',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'EG',
    text: 'Egypt',
    region: 'Africa',
  },
  {
    id: 'ddaa15ee-53a6-4171-aa37-ad88b2221052',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SV',
    text: 'El Salvador',
    region: 'Americas',
  },
  {
    id: '09903888-fef0-433b-b5f7-735ca754bd79',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GQ',
    text: 'Equatorial Guinea',
    region: 'Africa',
  },
  {
    id: '1e024cf5-5c51-4152-ad7b-76817870a5e5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ER',
    text: 'Eritrea',
    region: 'Africa',
  },
  {
    id: '86512d36-e59e-4704-82da-ca7cc81ae964',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'EE',
    text: 'Estonia',
    region: 'Europe',
  },
  {
    id: 'b531d0ec-697d-4a75-832a-ce9d0ca846a2',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SZ',
    text: 'Eswatini',
    region: 'Africa',
  },
  {
    id: '964673ec-350f-45ec-8a66-0a88fd5e31e2',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ET',
    text: 'Ethiopia',
    region: 'Africa',
  },
  {
    id: '03c635f5-e05e-4fba-881c-a116cab66cea',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FK',
    text: 'Falkland Islands (Malvinas)',
    region: 'Americas',
  },
  {
    id: '2c617a64-785a-4037-b20c-422d5e3ea1e3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FO',
    text: 'Faroe Islands',
    region: 'Europe',
  },
  {
    id: 'effb2faf-0956-4934-a99c-a112559e75a9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FJ',
    text: 'Fiji',
    region: 'Oceania',
  },
  {
    id: 'fed236e2-cfee-4c28-bc45-7b0327705c2e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FI',
    text: 'Finland',
    region: 'Europe',
  },
  {
    id: 'dae5f99f-dcff-459f-95ab-ef83de2edf3b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FR',
    text: 'France',
    region: 'Europe',
  },
  {
    id: '85104e1c-b7bb-4490-bf10-d9f2471b24d0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GF',
    text: 'French Guiana',
    region: 'Americas',
  },
  {
    id: '67f3d017-3e3a-4e3a-a755-a9592e83eb2e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PF',
    text: 'French Polynesia',
    region: 'Oceania',
  },
  {
    id: '63663811-e1b3-4231-a822-e8bfacbf2523',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TF',
    text: 'French Southern Territories',
    region: 'Africa',
  },
  {
    id: '9803a21d-2df9-4111-977e-3f3f47d83642',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GA',
    text: 'Gabon',
    region: 'Africa',
  },
  {
    id: '7572adc9-2717-4138-a4c7-f6e7465642e6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GM',
    text: 'Gambia',
    region: 'Africa',
  },
  {
    id: 'ef9e6e18-8da0-4928-ac58-17f19b819a4a',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GE',
    text: 'Georgia',
    region: 'Asia',
  },
  {
    id: 'c25debf0-1892-4636-adcb-168fda0bf15c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'DE',
    text: 'Germany',
    region: 'Europe',
  },
  {
    id: 'e978edde-601a-4243-9bc9-da9d309ee918',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GH',
    text: 'Ghana',
    region: 'Africa',
  },
  {
    id: 'cc559681-f1f4-4116-abcd-6f42274361c8',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GI',
    text: 'Gibraltar',
    region: 'Europe',
  },
  {
    id: '7abc84de-2c84-4b96-973a-75cb39c251b9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GR',
    text: 'Greece',
    region: 'Europe',
  },
  {
    id: 'b6834b32-ae7e-406b-9c72-4e53fa5f7f22',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GL',
    text: 'Greenland',
    region: 'Americas',
  },
  {
    id: '40d498ac-0102-49b6-9418-71bbf27780ca',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GD',
    text: 'Grenada',
    region: 'Americas',
  },
  {
    id: '4a5f2a10-9cab-44ed-a127-cbeb1f17d53e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GP',
    text: 'Guadeloupe',
    region: 'Americas',
  },
  {
    id: '4fe8c030-76c5-4b55-a58e-d05bc3ad6d34',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GU',
    text: 'Guam',
    region: 'Oceania',
  },
  {
    id: 'a438e7c6-37eb-41f8-9083-fe5b6092d743',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GT',
    text: 'Guatemala',
    region: 'Americas',
  },
  {
    id: 'f103767f-29b3-4f26-a584-ea4a0601d385',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GG',
    text: 'Guernsey',
    region: 'Europe',
  },
  {
    id: '38572cae-c30c-425c-84fd-6badfd166912',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GN',
    text: 'Guinea',
    region: 'Africa',
  },
  {
    id: 'c6683db9-0cef-4bd4-bfe0-ad01e7b95e17',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GW',
    text: 'Guinea-Bissau',
    region: 'Africa',
  },
  {
    id: '7b5e5364-521a-4bd8-b89b-bd9840bde466',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GY',
    text: 'Guyana',
    region: 'Americas',
  },
  {
    id: '470b5ba8-61b9-407b-a921-f35b414b8729',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HT',
    text: 'Haiti',
    region: 'Americas',
  },
  {
    id: '130cd183-c5d3-436d-abac-264a2c6162db',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HM',
    text: 'Heard Island and McDonald Islands',
    region: 'Oceania',
  },
  {
    id: 'beb89ed4-663e-4db1-ad02-8c8a6d7836aa',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VA',
    text: 'Holy See',
    region: 'Europe',
  },
  {
    id: '6d04d3d4-f87e-4174-bc59-878d5a120205',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HN',
    text: 'Honduras',
    region: 'Americas',
  },
  {
    id: '9a7dd71d-ee3d-4a2a-ba08-d7c3636db78d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HK',
    text: 'Hong Kong',
    region: 'Asia',
  },
  {
    id: '998fdb0e-bb5a-4f91-b189-58e786d14c48',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'HU',
    text: 'Hungary',
    region: 'Europe',
  },
  {
    id: 'c7a0eb3e-6578-44a7-b229-a0edab14cab4',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IS',
    text: 'Iceland',
    region: 'Europe',
  },
  {
    id: '854a6901-a82f-4a51-8a41-b81b70d3692d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IN',
    text: 'India',
    region: 'Asia',
  },
  {
    id: '27b3044d-82f9-4b72-a4e6-a8b5f4fe37c5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IR',
    text: 'Iran (Islamic Republic of)',
    region: 'Asia',
  },
  {
    id: 'bccb3f64-98e3-4e49-a065-889b4ac6f740',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IQ',
    text: 'Iraq',
    region: 'Asia',
  },
  {
    id: '02f12284-6f93-4387-a1f6-9e949678ce6e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IE',
    text: 'Ireland',
    region: 'Europe',
  },
  {
    id: '9fca7ae4-43d1-437f-a687-475b6900cf43',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IM',
    text: 'Isle of Man',
    region: 'Europe',
  },
  {
    id: 'bc0dc2b2-7de7-4719-8aa4-43bddf64ea98',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IL',
    text: 'Israel',
    region: 'Asia',
  },
  {
    id: '9f2d51c2-3e91-481b-bcde-6bea601a3abe',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'IT',
    text: 'Italy',
    region: 'Europe',
  },
  {
    id: '8788c580-978f-4cc2-a5b8-66bb43aec9cd',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'JM',
    text: 'Jamaica',
    region: 'Americas',
  },
  {
    id: '58504b17-def2-4083-839d-4263b5bafdf6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'JP',
    text: 'Japan',
    region: 'Asia',
  },
  {
    id: '55f34f7e-2b92-4174-9037-fb27720668b9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'JE',
    text: 'Jersey',
    region: 'Europe',
  },
  {
    id: '72c5c18a-efa0-40f9-af5d-ba83ce22f5dc',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'JO',
    text: 'Jordan',
    region: 'Asia',
  },
  {
    id: 'd440c034-baff-4488-890b-959342debe97',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KZ',
    text: 'Kazakhstan',
    region: 'Asia',
  },
  {
    id: '3e6424b5-24c9-4735-9e57-b7ae05d85f6d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KE',
    text: 'Kenya',
    region: 'Africa',
  },
  {
    id: '7d1508f6-bacc-4d6d-9992-3d7eabe0c813',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KI',
    text: 'Kiribati',
    region: 'Oceania',
  },
  {
    id: '107439be-f648-48a9-b266-ffdcfc7c74c3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KP',
    text: "Korea (Democratic People's Republic of)",
    region: 'Asia',
  },
  {
    id: 'b8b872bd-e04e-4dae-9325-6f2a68d3ee59',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KR',
    text: 'Korea, Republic of',
    region: 'Asia',
  },
  {
    id: '0d1088e2-9635-4879-9c13-0e19e3d17a44',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KW',
    text: 'Kuwait',
    region: 'Asia',
  },
  {
    id: 'c1c61cf8-8c31-4f35-82d9-baba416df1eb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KG',
    text: 'Kyrgyzstan',
    region: 'Asia',
  },
  {
    id: '15f260cd-cbc6-40f9-914e-d718dbd33fa6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LA',
    text: "Lao People's Democratic Republic",
    region: 'Asia',
  },
  {
    id: '2263c17d-8052-4aeb-91af-49bf1db52f64',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LV',
    text: 'Latvia',
    region: 'Europe',
  },
  {
    id: 'b0801d4b-aea8-4b3e-9acf-46a5a5a585f8',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LB',
    text: 'Lebanon',
    region: 'Asia',
  },
  {
    id: 'da168894-a5b8-48b3-84aa-570b4dbf1295',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LS',
    text: 'Lesotho',
    region: 'Africa',
  },
  {
    id: '71339d44-0c7e-4dba-b37d-efe53e0e28b9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LR',
    text: 'Liberia',
    region: 'Africa',
  },
  {
    id: 'dc59fca7-54d7-4e8f-a414-19eab79064aa',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LY',
    text: 'Libya',
    region: 'Africa',
  },
  {
    id: '1d9f145f-e93d-42ca-98c3-725628df3bbd',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LI',
    text: 'Liechtenstein',
    region: 'Europe',
  },
  {
    id: 'ad3b6449-3246-4157-8df0-04bbd390e8cf',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LT',
    text: 'Lithuania',
    region: 'Europe',
  },
  {
    id: '6fb1a3eb-59ec-4452-a835-572a78eb1cfa',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LU',
    text: 'Luxembourg',
    region: 'Europe',
  },
  {
    id: '88a514e6-5084-4a82-889e-62b5a37d51ce',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MO',
    text: 'Macao',
    region: 'Asia',
  },
  {
    id: '8fa4667f-8857-4d1d-a315-5753e14f4010',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MG',
    text: 'Madagascar',
    region: 'Africa',
  },
  {
    id: '23fa826f-5bb1-40d7-a827-28a2105aef3e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MW',
    text: 'Malawi',
    region: 'Africa',
  },
  {
    id: 'ad672075-40ef-48ac-9b3c-2bbb5e2dbbb6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MV',
    text: 'Maldives',
    region: 'Asia',
  },
  {
    id: 'b1e8dc22-ca49-423e-832b-aef336530e52',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ML',
    text: 'Mali',
    region: 'Africa',
  },
  {
    id: 'f20f5ad2-189d-44da-8a52-4fee9bfa7c06',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MT',
    text: 'Malta',
    region: 'Europe',
  },
  {
    id: '5a9f8c5d-2666-4b83-b7a6-f7b37bfbbe37',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MH',
    text: 'Marshall Islands',
    region: 'Oceania',
  },
  {
    id: '596c31dc-5317-4d18-a1e6-aec43f9bf305',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MQ',
    text: 'Martinique',
    region: 'Americas',
  },
  {
    id: '43b1e7fc-9ab9-4e5c-8807-639e2fa03af0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MR',
    text: 'Mauritania',
    region: 'Africa',
  },
  {
    id: 'c1bf4b03-eb0a-4fb7-8b8b-395b5b5d6b7d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MU',
    text: 'Mauritius',
    region: 'Africa',
  },
  {
    id: '427ee4be-ade3-4e8d-a2c0-4311bb1519ec',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'YT',
    text: 'Mayotte',
    region: 'Africa',
  },
  {
    id: 'e4fe7c7e-01a8-4a1f-b414-157b12a8eb38',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MX',
    text: 'Mexico',
    region: 'Americas',
  },
  {
    id: '62d64e39-0dae-4369-a889-0ad5736ee3e0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'FM',
    text: 'Micronesia (Federated States of)',
    region: 'Oceania',
  },
  {
    id: '38132222-078a-49ec-a0b2-87014189bc4f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MD',
    text: 'Moldova, Republic of',
    region: 'Europe',
  },
  {
    id: '486cc770-164c-4b7c-8b3f-701d3c71a399',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MC',
    text: 'Monaco',
    region: 'Europe',
  },
  {
    id: 'ad12c99a-dba3-430b-91dd-df247e77de61',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MN',
    text: 'Mongolia',
    region: 'Asia',
  },
  {
    id: 'e162a0d0-0693-4312-b475-84be9382426c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ME',
    text: 'Montenegro',
    region: 'Europe',
  },
  {
    id: '2d6bfdd9-f083-4122-8842-d95ae15bacb7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MS',
    text: 'Montserrat',
    region: 'Americas',
  },
  {
    id: '94836e54-0afe-4ef7-a297-19f291a35905',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MA',
    text: 'Morocco',
    region: 'Africa',
  },
  {
    id: '58233f82-1157-4bb5-9bfe-5d7e415332ad',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MZ',
    text: 'Mozambique',
    region: 'Africa',
  },
  {
    id: 'd9a38740-5a85-47a3-ac8d-2ff5abce84af',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MM',
    text: 'Myanmar',
    region: 'Asia',
  },
  {
    id: '3369794e-3bca-4603-bce8-9739cea16993',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NA',
    text: 'Namibia',
    region: 'Africa',
  },
  {
    id: '236bdd06-2019-441f-8b9d-5997434882fa',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NR',
    text: 'Nauru',
    region: 'Oceania',
  },
  {
    id: '3d4e75cc-ccdf-42a3-afe1-272c3993e430',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NP',
    text: 'Nepal',
    region: 'Asia',
  },
  {
    id: '1b3bd808-82c6-4993-85de-7d984e6f6023',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NL',
    text: 'Netherlands',
    region: 'Europe',
  },
  {
    id: '16572a6e-fcab-4665-8a2f-41b4d03b3976',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NC',
    text: 'New Caledonia',
    region: 'Oceania',
  },
  {
    id: 'c0cc20a1-026d-43c2-b419-11d1a4c6220f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NZ',
    text: 'New Zealand',
    region: 'Oceania',
  },
  {
    id: 'e790db0c-0a69-4061-929c-65593edb1aad',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NI',
    text: 'Nicaragua',
    region: 'Americas',
  },
  {
    id: '0b2c8398-912e-4842-822a-e6bfc1683228',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NE',
    text: 'Niger',
    region: 'Africa',
  },
  {
    id: '75976dbe-2715-42be-9eea-e654e0ae3f07',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NG',
    text: 'Nigeria',
    region: 'Africa',
  },
  {
    id: '1ed2617f-5edf-497c-a525-8282fb5fae88',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NU',
    text: 'Niue',
    region: 'Oceania',
  },
  {
    id: '682e6a71-ec1b-40c5-82b3-8171cb58d050',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NF',
    text: 'Norfolk Island',
    region: 'Oceania',
  },
  {
    id: '27f44bfe-8ddd-4684-95d5-51e08dbffe74',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MK',
    text: 'North Macedonia',
    region: 'Europe',
  },
  {
    id: 'd3c185a6-346e-44f9-86d7-0d0700d0773c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MP',
    text: 'Northern Mariana Islands',
    region: 'Oceania',
  },
  {
    id: 'f0a2c63e-e42d-4a3f-a6eb-1999f1b7f1ca',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'NO',
    text: 'Norway',
    region: 'Europe',
  },
  {
    id: '3ca2b0e9-6441-42e7-8b1e-ef1e7643838c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'OM',
    text: 'Oman',
    region: 'Asia',
  },
  {
    id: '922d4ec6-b2aa-475b-aca8-dbc55a8d2eba',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PK',
    text: 'Pakistan',
    region: 'Asia',
  },
  {
    id: '9c690814-5d6d-4b58-b056-6298d870795f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PW',
    text: 'Palau',
    region: 'Oceania',
  },
  {
    id: '48ae231b-5b07-49eb-b7b4-28f35f743f76',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PS',
    text: 'Palestine, State of',
    region: 'Asia',
  },
  {
    id: 'f5ad23a7-882f-41c6-8a20-9de43c6adb9e',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PA',
    text: 'Panama',
    region: 'Americas',
  },
  {
    id: 'c14e60c7-1def-4488-b04c-b51b1cc4a219',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PG',
    text: 'Papua New Guinea',
    region: 'Oceania',
  },
  {
    id: '92c49aa3-dfd6-4c1c-abdc-5bc62791b7d6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PY',
    text: 'Paraguay',
    region: 'Americas',
  },
  {
    id: 'ef377d4e-c1ff-49d4-8ad9-31bf5deb6b34',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PE',
    text: 'Peru',
    region: 'Americas',
  },
  {
    id: 'eb7633dd-93c8-471e-af29-5ac493615df7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PN',
    text: 'Pitcairn',
    region: 'Oceania',
  },
  {
    id: 'e9aa0065-0215-4e4b-a90d-201d09929216',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PL',
    text: 'Poland',
    region: 'Europe',
  },
  {
    id: 'f08e5bc1-a3b2-4f49-aa73-d740ac9c8798',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PT',
    text: 'Portugal',
    region: 'Europe',
  },
  {
    id: '7ad61702-16de-44f8-9d7e-bbd7756e633d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PR',
    text: 'Puerto Rico',
    region: 'Americas',
  },
  {
    id: '9e665398-b9d9-4aad-96ec-dc6b14d20d80',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'QA',
    text: 'Qatar',
    region: 'Asia',
  },
  {
    id: 'ea957859-da02-40f3-a96e-373f047e7155',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'RO',
    text: 'Romania',
    region: 'Europe',
  },
  {
    id: '45c3e872-7f30-405a-8e2c-43fc68fdc1d1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'RU',
    text: 'Russian Federation',
    region: 'Europe',
  },
  {
    id: 'e93a6927-4324-42da-bfba-26c8d5095d22',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'RW',
    text: 'Rwanda',
    region: 'Africa',
  },
  {
    id: '9713ec9e-e729-451f-84dd-2a02ad276fdf',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'BL',
    text: 'Saint Barthélemy',
    region: 'Americas',
  },
  {
    id: '8eb26a6a-4749-4ce8-b1a6-b644eb8f6ac8',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SH',
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    region: 'Africa',
  },
  {
    id: '35e0e199-139e-4d73-b307-ddb010f18cfb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'KN',
    text: 'Saint Kitts and Nevis',
    region: 'Americas',
  },
  {
    id: '757f6445-aba9-41b8-8aea-f3046d68f0b1',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LC',
    text: 'Saint Lucia',
    region: 'Americas',
  },
  {
    id: '0c7467ba-26c2-40c6-95a6-7e378b03e0d5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'MF',
    text: 'Saint Martin (French part)',
    region: 'Americas',
  },
  {
    id: 'c21b6421-1553-44ff-a172-064b918d7b91',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'PM',
    text: 'Saint Pierre and Miquelon',
    region: 'Americas',
  },
  {
    id: 'e0e25c07-b16a-404a-ba72-8da3db62c74b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VC',
    text: 'Saint Vincent and the Grenadines',
    region: 'Americas',
  },
  {
    id: 'b5d3f5d9-d279-490e-b9da-ded0f77f9fab',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'WS',
    text: 'Samoa',
    region: 'Oceania',
  },
  {
    id: '616bd229-690a-4a9a-b00d-f0fabdf44a67',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SM',
    text: 'San Marino',
    region: 'Europe',
  },
  {
    id: '36c76783-d39d-4680-8671-d06f48c763a3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ST',
    text: 'Sao Tome and Principe',
    region: 'Africa',
  },
  {
    id: '5103544f-5cb1-443e-a33e-a42f5b779266',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SA',
    text: 'Saudi Arabia',
    region: 'Asia',
  },
  {
    id: '7b97836e-6b1a-4096-96bf-ae7d5b79ddc5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SN',
    text: 'Senegal',
    region: 'Africa',
  },
  {
    id: '8903182f-1503-4cfa-a0c3-0aa442a509b7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'RS',
    text: 'Serbia',
    region: 'Europe',
  },
  {
    id: 'b1da8833-a6ce-4e69-b804-26bc356d6e43',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SC',
    text: 'Seychelles',
    region: 'Africa',
  },
  {
    id: '72191058-8da5-4102-9ce1-7786770d3f9d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SL',
    text: 'Sierra Leone',
    region: 'Africa',
  },
  {
    id: '574c0d24-37fc-4700-8227-d5606196a432',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SX',
    text: 'Sint Maarten (Dutch part)',
    region: 'Americas',
  },
  {
    id: '9b4fd763-2018-40d9-b1a2-a4d96efb17eb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SK',
    text: 'Slovakia',
    region: 'Europe',
  },
  {
    id: '6a08507c-b50a-4f15-b46a-4f24aca8efe2',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SI',
    text: 'Slovenia',
    region: 'Europe',
  },
  {
    id: '1906f613-5d86-4f5c-af1c-5c95e5141f50',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SB',
    text: 'Solomon Islands',
    region: 'Oceania',
  },
  {
    id: '809946c4-7754-498b-9882-67de5bd195b8',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SO',
    text: 'Somalia',
    region: 'Africa',
  },
  {
    id: '4d32a38e-0ba4-4e07-95fc-fe74f17c7ab4',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ZA',
    text: 'South Africa',
    region: 'Africa',
  },
  {
    id: '009950cf-5c22-454c-bb80-f66ca75c5ea9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
    region: 'Americas',
  },
  {
    id: '0046434b-2609-4b3c-a1b1-27749bfe9961',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SS',
    text: 'South Sudan',
    region: 'Africa',
  },
  {
    id: '27efc571-a554-4de6-8306-397668dc0374',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ES',
    text: 'Spain',
    region: 'Europe',
  },
  {
    id: '2cdd0510-2204-439c-b76c-44b65edabb91',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'LK',
    text: 'Sri Lanka',
    region: 'Asia',
  },
  {
    id: '10309c6c-c0a6-4067-9baf-72522629be1c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SD',
    text: 'Sudan',
    region: 'Africa',
  },
  {
    id: '3725f767-4439-428d-bb14-96bf507b01cd',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SR',
    text: 'Suriname',
    region: 'Americas',
  },
  {
    id: '94d86d61-6f4f-463c-b28b-bd3d042bba71',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SJ',
    text: 'Svalbard and Jan Mayen',
    region: 'Europe',
  },
  {
    id: 'fdf1bb64-9c0a-42a2-9243-173e91bf5bfb',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SE',
    text: 'Sweden',
    region: 'Europe',
  },
  {
    id: '5c32ac5c-2e85-4049-bc53-cf243072923b',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'CH',
    text: 'Switzerland',
    region: 'Europe',
  },
  {
    id: '9265e5db-cb00-4d88-8038-789ab4650ead',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'SY',
    text: 'Syrian Arab Republic',
    region: 'Asia',
  },
  {
    id: 'f7f751af-087f-4591-81a0-c6b98a65941d',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TW',
    text: 'Taiwan, Province of China',
    region: 'Asia',
  },
  {
    id: '1c075a79-d36d-406a-b2a5-f879fccb54de',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TJ',
    text: 'Tajikistan',
    region: 'Asia',
  },
  {
    id: 'a5185afc-12a9-4b30-a64e-cccd9b0317d6',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TZ',
    text: 'Tanzania, United Republic of',
    region: 'Africa',
  },
  {
    id: '0d0ecc6e-fa56-4c59-bd6c-183626be2668',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TL',
    text: 'Timor-Leste',
    region: 'Asia',
  },
  {
    id: '5d3ac926-5e23-4d4b-8a86-184bdeba38d9',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TG',
    text: 'Togo',
    region: 'Africa',
  },
  {
    id: '657a24be-d2f5-450a-87aa-5921df99fb91',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TK',
    text: 'Tokelau',
    region: 'Oceania',
  },
  {
    id: 'ad95934f-a89c-4470-bff3-978641c19786',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TO',
    text: 'Tonga',
    region: 'Oceania',
  },
  {
    id: 'e85468af-602e-4753-ac4d-2fdf10ff881f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TT',
    text: 'Trinidad and Tobago',
    region: 'Americas',
  },
  {
    id: '4d1f0de5-6d54-4550-94c1-1b5840a24d35',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TN',
    text: 'Tunisia',
    region: 'Africa',
  },
  {
    id: '4e98397e-e126-409a-914f-490c3fcff43a',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TR',
    text: 'Turkey',
    region: 'Asia',
  },
  {
    id: 'b5d04e85-f8e9-4b69-b585-7b731b8931e4',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TM',
    text: 'Turkmenistan',
    region: 'Asia',
  },
  {
    id: '543d9bad-c9b3-40a8-862e-f6271b07ed9a',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TC',
    text: 'Turks and Caicos Islands',
    region: 'Americas',
  },
  {
    id: '34c06c2f-ac3f-4e52-8809-b072cafa921c',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'TV',
    text: 'Tuvalu',
    region: 'Oceania',
  },
  {
    id: '5e255ee2-3f5e-40e8-b2d4-9ab73c5c3b43',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'UG',
    text: 'Uganda',
    region: 'Africa',
  },
  {
    id: '8024f7c6-3406-4d21-a872-635929450f02',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'UA',
    text: 'Ukraine',
    region: 'Europe',
  },
  {
    id: 'd42a4f5f-cc42-46db-b2cf-55278db7f502',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'AE',
    text: 'United Arab Emirates',
    region: 'Asia',
  },
  {
    id: '01b0d2da-669b-4b3e-bfb1-28f8647113e5',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'GB',
    text: 'United Kingdom of Great Britain and Northern Ireland',
    region: 'Europe',
  },
  {
    id: '425aa0d5-c687-4c27-9380-bc025988dd1f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'US',
    text: 'United States of America',
    region: 'Americas',
  },
  {
    id: 'c175e8ea-037e-48aa-9130-2289d2844563',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'UM',
    text: 'United States Minor Outlying Islands',
    region: 'Oceania',
  },
  {
    id: 'a7933a8f-f12a-426c-bc68-fdf156f7a4b7',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'UY',
    text: 'Uruguay',
    region: 'Americas',
  },
  {
    id: '93539907-612f-46ab-b14f-c419a4d5989f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'UZ',
    text: 'Uzbekistan',
    region: 'Asia',
  },
  {
    id: 'b499d386-efbe-44ee-b453-d9dc365072b4',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VU',
    text: 'Vanuatu',
    region: 'Oceania',
  },
  {
    id: '38ace0a1-81b0-49e1-a3b6-014717a0ba94',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VE',
    text: 'Venezuela (Bolivarian Republic of)',
    region: 'Americas',
  },
  {
    id: '08a20aca-2ba3-49b5-885d-b60586666195',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VN',
    text: 'Vietnam',
    region: 'Asia',
  },
  {
    id: '42de8b2e-8aa5-4639-a46c-f05c1626ce11',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VG',
    text: 'Virgin Islands (British)',
    region: 'Americas',
  },
  {
    id: '7e24e518-ab89-4c59-8530-1e56ef7d0457',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'VI',
    text: 'Virgin Islands (U.S.)',
    region: 'Americas',
  },
  {
    id: '65f6f90a-e722-4e40-8e1e-962e7879cb90',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'WF',
    text: 'Wallis and Futuna',
    region: 'Oceania',
  },
  {
    id: '58595f08-56c3-4aad-b4c1-746b1c7b7e1f',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'EH',
    text: 'Western Sahara',
    region: 'Africa',
  },
  {
    id: '9fc64957-7dea-4d60-9d39-71d8462a85e0',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'YE',
    text: 'Yemen',
    region: 'Asia',
  },
  {
    id: 'fdaffeb0-f288-465c-b6d6-fc8cd6ed9de3',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ZM',
    text: 'Zambia',
    region: 'Africa',
  },
  {
    id: 'b2beec26-2ab1-4fef-9d65-215bb06b5afa',
    createdAt: '2021-04-19T04:13:18.272313Z',
    updatedAt: '2021-04-19T04:13:18.272313Z',
    value: 'ZW',
    text: 'Zimbabwe',
    region: 'Africa',
  },
]
