import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/transactionV2'

export default {
  namespaced: true,
  state: {
    tableFields: get('transactionV2TableFields', fields),
    tableData: [],
    tablePagination: get('transactionV2TablePagination'),
    tableKeyword: get('transactionV2TableKeyword'),
    tableSort: get('transactionV2TableSort'),
    tableFilter: get('transactionV2TableFilter', []),
    detailData: get('transactionV2DetailData'),
    userData: [],
    loadingTable: false,
    loadingSubmit: false,
    loadingDetail: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableData: state => state.tableData.map(item => {
      let supporter = null
      let creator = null

      if (item.usecase === 'USER_TIER_ONETIME' || item.usecase === 'USER_TIER_RECURRING') {
        creator = {
          id: item.usecase_data?.data?.subscriber_user_id,
          nickname: item.usecase_data?.data?.subscriber_user_nickname || '(Guest)',
        }
      } else if (item.usecase === 'DONATION') {
        const id = item.buyer_user_id || item.usecase_data?.data?.donator_user_id
        let nickname = item.buyer_user_nickname || item.usecase_data?.data?.donator_user_nickname

        if (!id && nickname) {
          nickname = `${nickname} (Guest)`
        } else if (!id) {
          nickname = '(Guest)'
        }

        supporter = {
          id,
          avatar: item.buyer_user_avatar_url,
          nickname,
          email: item.buyer_user_email || item.usecase_data?.data?.donator_user_email,
        }
        creator = {
          id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        }
      } else if (item.usecase === 'MIGRATION_V1'
        || item.usecase === 'EVENT'
        || item.usecase === 'TOPUP_REDEEM') {
        supporter = {
          id: item.buyer_user_id || item.usecase_data?.data?.user_id,
          avatar: item.buyer_user_avatar_url,
          nickname: item.buyer_user_nickname || item.usecase_data?.data?.user_nickname,
        }
      } else if (item.usecase === 'TOPUP'
        || item.usecase === 'WITHDRAWAL') {
        creator = {
          id: item.seller_user_id || item.usecase_data?.data?.user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.user_nickname,
        }
      } else if (item.usecase === 'USER_MEMBERSHIP_ONETIME'
        || item.usecase === 'USER_MEMBERSHIP_RECURRING') {
        supporter = {
          id: item.buyer_user_id || item.usecase_data?.data?.subscriber_user_id,
          avatar: item.buyer_user_avatar_url,
          nickname: item.buyer_user_nickname || item.usecase_data?.data?.subscriber_user_nickname,
          email: item.buyer_user_email || item.usecase_data?.data?.subscriber_user_email,
        }
        creator = {
          id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        }
      } else if (item.usecase === 'MERCHANDISE'
        || item.usecase === 'PAY_PER_VIEW'
        || item.usecase === 'SERVICE'
        || item.usecase === 'MERCHANDISE_COMPLETE'
        || item.usecase === 'SERVICE_COMPLETE'
        || item.usecase === 'MERCHANDISE_REFUND'
        || item.usecase === 'SERVICE_REFUND') {
        supporter = {
          id: item.buyer_user_id || item.usecase_data?.data?.buyer_user_id,
          avatar: item.buyer_user_avatar_url,
          nickname: item.buyer_user_nickname || item.usecase_data?.data?.buyer_user_nickname,
          email: item.buyer_user_email || item.usecase_data?.data?.buyer_user_email,
        }
        creator = {
          id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        }
      } else if (item.usecase === 'DIGITAL_GOODS') {
        const id = item.buyer_user_id || item.usecase_data?.data?.buyer_user_id
        let nickname = item.buyer_user_nickname || item.usecase_data?.data?.buyer_user_nickname

        if (!id && nickname) {
          nickname = `${nickname} (Guest)`
        } else if (!id) {
          nickname = '(Guest)'
        }

        supporter = {
          id,
          avatar: item.buyer_user_avatar_url,
          nickname,
          email: item.buyer_user_email || item.usecase_data?.data?.buyer_user_email,
        }
        creator = {
          id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        }
      }

      return ({
        ...item,
        id: item.human_id,
        usecase: item.usecase,
        title2: item.title,
        supporter,
        creator,

        orderNumber: null,
        status: null,
        payment: null,
        updatedAt: null,
      })
    }),
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      search_keywords: state.tableKeyword,
      ...getters.tableFilter,
      order_by: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      per_page: rootState.appConfig.settings.tablePerPage,
    }),
    userTableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('transactionV2TableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('transactionV2TableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('transactionV2TablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('transactionV2TableKeyword', val)
      state.tableKeyword = val
    },
    SET_TABLE_SORT(state, val) {
      set('transactionV2TableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('transactionV2TableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_DETAIL_DATA(state, val) {
      set('transactionV2DetailData', val)
      state.detailData = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    getTableData({
      state, commit, getters, rootState,
    }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v2/payment/admin/transactions', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])

          commit('SET_TABLE_PAGINATION', {
            currentPage: state.tablePagination?.currentPage ?? 1,
            totalPage: data.total / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
            perPage: state.tablePagination?.perPage ?? rootState.appConfig.settings.tablePerPage,
            totalData: data.total,
          })
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    // getSubscriptionTransactionTableData({
    //   state, commit, getters, rootState,
    // }, humanId) {
    //   commit('SET_LOADING_TABLE', true)
    //
    //   const { tableParams: params } = getters
    //   this._vm.$http.get('/v2/payment/admin/transactions', {
    //     params: {
    //       ...params,
    //       search_keywords: humanId,
    //       usecases_client: 'SUBSCRIPTION_TX',
    //     },
    //   })
    //     .then(({ data }) => {
    //       commit('SET_TABLE_DATA', data.data || [])
    //
    //       commit('SET_TABLE_PAGINATION', {
    //         currentPage: state.tablePagination?.currentPage ?? 1,
    //         totalPage: data.total / (state.tablePagination?.currentPage ?? rootState.appConfig.settings.tablePerPage),
    //         perPage: state.tablePagination?.perPage ?? rootState.appConfig.settings.tablePerPage,
    //         totalData: data.total,
    //       })
    //     })
    //     .finally(() => {
    //       commit('SET_LOADING_TABLE', false)
    //     })
    // },
    search(_, params) {
      return this._vm.$http.get('/v1/bo/users', { params })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.nickname || item.email,
        })))
        .catch(() => [])
    },
    getSubscriptionTransactionTableData(_, humanId) {
      return this._vm.$http.get('/v2/payment/admin/transactions', {
        params: {
          page: 1,
          per_page: 200,
          search_keywords: humanId,
          usecases_client: 'SUBSCRIPTION_TX',
        },
      })
        .then(({ data }) => data.data)
        .catch(() => [])
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/wallets/transactions/detail?id=${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    changeStatus({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/wallets/transactions/${id}/statuses`, data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    downloadTableData({ state, commit, getters }) {
      commit('SET_LOADING_SUBMIT', true)
      const { tableParams: params } = getters

      const payload = {
        ...params,
        statuses: params.statuses ? [params.statuses] : undefined,
        orderBy: `${state.tableSort.key.replace(/_([a-z])/g, (_, res) => res.toUpperCase())} ${state.tableSort.direction}`,
        order_by: undefined,
        per_page: undefined,
        perPage: Number(params.per_page),
        payment_srcs: undefined,
        paymentSrcs: params.payment_srcs ? params.payment_srcs.split(',') : undefined,
        search_keywords: undefined,
        searchKeywords: params.search_keywords,
        start_date: undefined,
        startDate: params.start_date,
        usecases: params.usecases ? params.usecases : undefined,
      }

      return this._vm.$http.post('/v1/bo/users/export/transactions', payload)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    async getWalletTableData({ commit, getters }, walletId) {
      commit('SET_LOADING_TABLE', true)

      const { userTableParams: params } = getters
      await this._vm.$http.get('/v1/bo/users/transactions', {
        params: {
          ...params,
          walletID: walletId,
        },
      })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getUserData({ commit }, keyword) {
      if (!keyword) {
        commit('SET_USER_DATA', [])
        return null
      }

      return this._vm.$http.get('/v1/bo/users/', {
        params: {
          page: 1,
          perPage: 6,
          keyword,
        },
      })
        .then(({ data }) => {
          const userData = data.data ? data.data.map(item => ({
            value: item.id,
            text: item.nickname || item.email,
            subtitle: item.nickname ? item.email : undefined,
            image: item.avatar,
          })) : []

          commit('SET_USER_DATA', userData)

          return userData
        })
    },
    resendEmail({ commit }, { orderNumber = undefined, humanId = undefined, email = '' }) {
      commit('SET_LOADING_SUBMIT', true)
      return this._vm.$http.post('/v2/payment/user-info/admin/digital-goods', {
        v1_order_number: orderNumber,
        human_id: humanId,
        buyer_user_email_opt: email,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },

    updateTransaction({ commit, dispatch }, { paymentId, paymentStatus }) {
      commit('SET_LOADING_SUBMIT', true)
      return this._vm.$http.patch('/v2/payment/admin/transactions', { usecase: paymentStatus, usecase_data: { human_id: paymentId } })
        .finally(() => {
          dispatch('transactionV2/getTableData')
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
